import api from '../../api/api';
import {GET_CUSTOM_FORM_DATA, SET_BASE_PASS_SELECTED} from '../actionTypes';
import {UPDATE_CUSTOM_FORM_DATA} from '../mutationTypes';
import {BASIC_PASS_AVAILABLE, BASIC_PASS_FIELD_NAME, CUSTOM_FORM_ALIASES} from '@/const';

export default {
  [GET_CUSTOM_FORM_DATA]: async ({
    commit,
    state,
    getters,
  }, alias) => {
    const form = await api.customFormApi.get_custom_form(alias);
    commit(UPDATE_CUSTOM_FORM_DATA, {
      data: form,
      alias: alias,
      getters,
    });
    return form;
  },
  [SET_BASE_PASS_SELECTED]: async ({dispatch}) => {
    if (!BASIC_PASS_AVAILABLE) return;
    await api.customFormApi.update_custom_form(CUSTOM_FORM_ALIASES.PASSES, {
      [BASIC_PASS_FIELD_NAME]: 1,
    });
    return await dispatch(GET_CUSTOM_FORM_DATA, CUSTOM_FORM_ALIASES.PASSES);
  },
};
