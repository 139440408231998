import {GET_PAGE_ID_FOR_URL} from '@/store/getterTypes';

export default {
  getChannelRoute(state) {
    const newRoute = [];
    state?.pages?.forEach((page) => {
      newRoute.push({
        path: page.url,
        name: page.name,
        meta: {
          title: page.name,
          scrollToTop: true,
          id: page.id,
        },
        component: () => import(
            /* webpackChunkName: "schedule-view" */ '../../views/CustomPageScreen.vue'),
      });
    });
    return newRoute;
  },
  /**
   * @param {object} state
   * @return {function(string): string|number}
   */
  [GET_PAGE_ID_FOR_URL](state) {
    return (searchUrl) => {
      return state.pages?.find?.(({url, alt_name: alt}) => {
        const correctedSearch = url.startsWith('/') ? `/${searchUrl}` : searchUrl;
        return url === correctedSearch || alt === searchUrl;
      } )?.id;
    };
  },
};
